<template>

        <div class="color--picker">
            <b-avatar style="max-width: unset"  :id="'popover--'+uniqPrefix"
                       rounded

                       size="37"
                       variant="light-secondary cursor-pointer"
            >
            <span :style="{'background-color': (chosenColor? ( (chosenColor && chosenColor.hex8)? chosenColor.hex8 : chosenColor ): '')}" class="color-preview"></span>

            </b-avatar>
            <b-popover
                    custom-class="picker-popover"
                    class="bg-info"
                    :target="'popover--'+uniqPrefix"
                    triggers="click blur"

                    placement="top"
                    variant="secondary"
            >
                <template #title>

                </template>
                <div class="tooltip-content">
                    <color-picker :value="colorData" @input="colorData = $event ; $emit('input', $event)" ></color-picker>
                </div>
            </b-popover>

        </div>

</template>

<script>
    import { Chrome } from 'vue-color'
    import {BPopover, BAvatar} from 'bootstrap-vue'
    export default {
        model: {
            prop: 'chosenColor',
            event: 'input'
        },
        props: {
            chosenColor: {default: ''},
        },
        components: {
            BAvatar, BPopover,
            'color-picker': Chrome
        },
        data() {
            return {
                colorData: '',
                uniqPrefix: (new Date()).getTime()
            }
        },

    };
</script>

